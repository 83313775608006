import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import { retrieveOrders } from "api/db"
import ProductCell from "../../components/Product/ProductCell"

const Home = ({ data }) => {
  const [sortBy, setSortBy] = useState("")
  const [category, setCategory] = useState()
  const [subCategory, setSubCategory] = useState()
  const [orderInProgress, setOrderInProgress] = useState(false)
  const [activeOrder, setActiveOrder] = useState([])

  let products = data.allGoogleSpreadsheetSheet1.nodes

  let uid
  // if logged it retreieve to see if there's an active order!!
  if (typeof window !== "undefined") {
    uid = JSON.parse(localStorage.getItem("uid"))
  } else {
    uid = "null"
  }

  if (uid != "null" && uid != null && !orderInProgress) {
    uid = JSON.parse(uid)
    retrieveOrders(uid).then(snapshot => {
      let order = snapshot.val()[Object.keys(snapshot.val())]
      if (order.status === "in-progress") {
        let items = order
        delete items.dateUpdated
        delete items.orderNumber
        delete items.status
        delete items.uid
        setOrderInProgress(true)
        setActiveOrder(items)
      } else {
        // no order in progress
      }
    })
  }

  let stationarySubCategories = Array.from(
    new Set(
      products.map(product => {
        if (product.category === "stationary") {
          return product.subcategory
        }
      })
    )
  ).sort()

  if (subCategory && sortBy === "subcategory") {
    products = products.filter(product => product.subcategory === subCategory)
  } else if (category && sortBy === "category") {
    products = products.filter(product => product.category === category)
  }

  function format(category) {
    if (category) {
      return category.replace(/-/g, " ")
    }
  }

  return (
    <Layout>
      <SEO
        title="Stationary Products | Tick Tock Inc."
        description="Importer and Distributor of General Merchandise in the Greater New York City Area."
      />
      <div className="tabs is-toggle is-centered is-toggle-rounded">
        <ul>
          <li>
            <Link to="/categories/all">All</Link>
          </li>
          <li>
            <Link to="/categories/aluminum">Aluminum</Link>
          </li>
          <li>
            <Link to="/categories/cleaning">Cleaning</Link>
          </li>
          <li>
            <Link to="/categories/foam">Foam</Link>
          </li>
          <li>
            <Link to="/categories/health-and-beauty">Health & Beauty</Link>
          </li>
          <li>
            <Link to="/categories/aid">Medicine & Aid</Link>
          </li>
          <li>
            <Link to="/categories/mops-and-brooms">Mops & Brooms</Link>
          </li>
          <li>
            <Link to="/categories/plastic">Plastic</Link>
          </li>
          <li>
            <Link to="/categories/paper">Paper</Link>
          </li>
          <li className="is-active">
            <Link to="/categories/stationary">Stationary</Link>
          </li>
          <li>
            <Link to="/categories/misc">Miscellaneous</Link>
          </li>
        </ul>
      </div>
      <br />
      <div className="columns">
        <aside className="menu column is-2 is-hidden-mobile">
          <h1 className="menu-label is-size-6">
            <Link to="/categories/all"> All Products</Link>
          </h1>
          <ul className="menu-list">
            <li>
              <Link className="has-text-link" to="/categories/aluminum">
                Aluminum
              </Link>
            </li>

            <li>
              <Link className="has-text-link" to="/categories/cleaning">
                Cleaning
              </Link>
            </li>

            <li>
              <Link className="has-text-link" to="/categories/foam">
                Foam
              </Link>
            </li>

            <li>
              <Link
                className="has-text-link"
                to="/categories/health-and-beauty"
              >
                Health & Beauty
              </Link>
            </li>

            <li>
              <Link className="has-text-link" to="/categories/aid">
                Medicine & Aid
              </Link>
            </li>

            <li>
              <Link className="has-text-link" to="/categories/mops-and-brooms">
                Mops & Brooms
              </Link>
            </li>

            <li>
              <Link className="has-text-link" to="/categories/plastic">
                Plastic
              </Link>
            </li>

            <li>
              <Link className="has-text-link" to="/categories/paper">
                Paper
              </Link>
            </li>

            <li
              onClick={() => {
                setCategory("stationary")
                setSortBy("category")
              }}
            >
              <a className="has-text-link" href="/categories/plastic">
                Stationary
              </a>
            </li>
            <li>
              <ul>
                {stationarySubCategories.map(category => (
                  <li
                    onClick={() => {
                      setSubCategory(category)
                      setSortBy("subcategory")
                    }}
                  >
                    <a href="#">{format(category)}</a>
                  </li>
                ))}
              </ul>
            </li>

            <li>
              <Link className="has-text-link" to="/categories/misc">
                Miscellaneous
              </Link>
            </li>
          </ul>
        </aside>
        <div className="column is-10">
          <div className="columns is-multiline">
            {products.map(product => (
              <ProductCell
                product={product}
                uid={uid}
                orderInProgress={orderInProgress}
                activeOrder={activeOrder}
                key={product.itemCode}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query stationaryCategoryQuery {
    allGoogleSpreadsheetSheet1(
      filter: {
        category: { eq: "stationary" }
        deleted: { ne: "yes" }
        stock: { eq: "yes" }
      }
    ) {
      nodes {
        category
        subcategory
        itemCode
        packing
        price
        description
        stock
      }
    }
  }
`

export default Home
